export const DoubleDownArrow = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.43558 3.05701C2.70834 2.7442 3.18216 2.7125 3.49389 2.98621L6.73609 5.83299C6.88707 5.96555 7.11295 5.96555 7.26393 5.83299L10.5061 2.98621C10.8179 2.7125 11.2917 2.7442 11.5644 3.05701C11.8372 3.36982 11.8056 3.84529 11.4939 4.11899L7.55974 7.57333C7.23927 7.85471 6.76075 7.85471 6.44028 7.57333L2.50613 4.11899C2.1944 3.84529 2.16282 3.36982 2.43558 3.05701Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.43558 7.57263C2.70834 7.25982 3.18216 7.22813 3.49389 7.50183L6.73609 10.3486C6.88707 10.4812 7.11295 10.4812 7.26393 10.3486L10.5061 7.50183C10.8179 7.22813 11.2917 7.25982 11.5644 7.57263C11.8372 7.88544 11.8056 8.36091 11.4939 8.63462L7.55974 12.089C7.23927 12.3703 6.76075 12.3703 6.44028 12.089L2.50613 8.63462C2.1944 8.36091 2.16282 7.88544 2.43558 7.57263Z'
        fill='white'
      />
    </svg>
  );
};

export const DoubleRightArrow = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.75702 11.5644C2.44421 11.2917 2.41251 10.8179 2.68622 10.5061L5.53345 7.26341C5.66587 7.1126 5.66587 6.88742 5.53345 6.73661L2.68622 3.49389C2.41252 3.18216 2.44421 2.70834 2.75702 2.43558C3.06983 2.16282 3.5453 2.1944 3.81901 2.50613L7.27334 6.44028C7.55472 6.76075 7.55472 7.23927 7.27334 7.55974L3.81901 11.4939C3.5453 11.8056 3.06983 11.8372 2.75702 11.5644Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.27264 11.5644C6.95983 11.2917 6.92814 10.8179 7.20184 10.5061L10.0491 7.26341C10.1815 7.1126 10.1815 6.88742 10.0491 6.73661L7.20185 3.49389C6.92814 3.18216 6.95983 2.70834 7.27264 2.43558C7.58545 2.16282 8.06092 2.1944 8.33463 2.50613L11.789 6.44028C12.0703 6.76075 12.0703 7.23927 11.789 7.55974L8.33463 11.4939C8.06092 11.8056 7.58545 11.8372 7.27264 11.5644Z'
      />
    </svg>
  );
};

export const DoubleUpArrow = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5644 11.243C11.2917 11.5558 10.8179 11.5875 10.5061 11.3138L7.26341 8.46655C7.1126 8.33413 6.88742 8.33413 6.73661 8.46655L3.49389 11.3138C3.18216 11.5875 2.70834 11.5558 2.43558 11.243C2.16282 10.9302 2.1944 10.4547 2.50613 10.181L6.44028 6.72666C6.76075 6.44527 7.23927 6.44528 7.55974 6.72666L11.4939 10.181C11.8056 10.4547 11.8372 10.9302 11.5644 11.243Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5644 6.72736C11.2917 7.04017 10.8179 7.07186 10.5061 6.79816L7.26341 3.95093C7.1126 3.81851 6.88742 3.81851 6.73661 3.95093L3.49389 6.79815C3.18216 7.07186 2.70834 7.04017 2.43558 6.72736C2.16282 6.41455 2.1944 5.93908 2.50613 5.66537L6.44028 2.21104C6.76075 1.92965 7.23927 1.92965 7.55974 2.21104L11.4939 5.66537C11.8056 5.93908 11.8372 6.41455 11.5644 6.72736Z'
        fill='white'
      />
    </svg>
  );
};

export const DoubleLeftArrow = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
      className={props.className ?? 'w-3.5 h-3.5'}
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M10.85.783a.855.855 0 01.08 1.21L7.677 5.699a.456.456 0 000 .602l3.254 3.706a.855.855 0 01-.08 1.21.862.862 0 01-1.214-.081L5.688 6.64a.969.969 0 010-1.28L9.636.864a.862.862 0 011.214-.08z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M5.689.783a.855.855 0 01.08 1.21L2.517 5.699a.456.456 0 000 .602l3.254 3.706a.855.855 0 01-.081 1.21.862.862 0 01-1.214-.081L.527 6.64a.969.969 0 010-1.28L4.475.864A.862.862 0 015.69.784z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};
